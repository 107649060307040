import React, { useState, useEffect } from 'react';
import { Button, Box, Typography, CircularProgress, Alert, Modal, IconButton , Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Card, CardContent  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import axios from '../axios';
import PresenzeDinoAlunniDetail from './PresenzeDinoAlunniDetail';
import dayjs from 'dayjs';
import 'dayjs/locale/it'; // Importa la lingua italiana

const CorsoLezioni = ({ courseId }) => {
    const [lezioni, setLezioni] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [selectedLezione, setSelectedLezione] = useState(null);
    const [openDialog, setOpenDialog] = useState(false); // Stato per il dialog di conferma

    const fetchLezioni = async () => {
        try {
            const token = localStorage.getItem('token'); // Get the token from local storage
            const response = await axios.post('/datahandler/get-corso-lezioni', {
                token,
                idCorso: courseId
            });

            setLezioni(response.data);
        } catch (error) {
            setError('Errore nel recupero delle lezioni');
        } finally {
            setLoading(false);
        }
    };

    const handleUnlockPresenze = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.post('/datahandler/unlock-presenze', {
                token,
                idCorso: courseId
            });
            setOpenDialog(false); // Chiude il dialog dopo la conferma
            fetchLezioni(); // Ricarica le lezioni dopo lo sblocco
        } catch (error) {
            console.error('Errore nello sblocco delle presenze:', error);
        }
    };


    useEffect(() => {
       
        fetchLezioni();
    }, [courseId]);

    const handleOpenModal = (lezione) => {
        setSelectedLezione(lezione);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedLezione(null);
        fetchLezioni();
    };

    
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        
        <Box textAlign="center">
            <Typography variant="h5" gutterBottom>
                Registro presenze
            </Typography>
            <Box display="flex" justifyContent="center" flexWrap="wrap">
                {lezioni.length === 0 ? (




                <Card sx={{ maxWidth: 600, mx: 'auto', mb: 3, p: 2 }}> {/* Imposta una larghezza massima e margini */}
                <CardContent>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body1">
                            Nessuna lezione trovata o corso non extrascolastico.
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenDialog}
                            sx={{ ml: 2 }} // Margine sinistro per distanziare il pulsante dal testo
                        >
                            Gestisci comunque il registro presenze
                        </Button>
                    </Box>
                </CardContent>
                </Card>






                ) : (
                    lezioneButtons(lezioni, handleOpenModal)
                )}
            </Box>


            {/* Modal */}
            <Modal
    open={openModal}
    onClose={handleCloseModal}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
>
    <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%', // Imposta la larghezza al 90% dello schermo
        maxWidth: 600, // Imposta la larghezza massima
        maxHeight: '90vh', // Limita l'altezza del modal
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        overflowY: 'auto', // Abilita lo scrolling verticale
        position: 'relative', // Ensure the close button is positioned correctly
    }}>
        {/* Close Button */}
        <IconButton
            onClick={handleCloseModal}
            sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: 'grey.500',
            }}
        >
            <CloseIcon />
        </IconButton>

        {selectedLezione && (
            <PresenzeDinoAlunniDetail
                idCorso={courseId}
                idLezione={selectedLezione.id}
                numLezione={selectedLezione.progr_lezione}
                statoLezione={selectedLezione.status}
                dataLezione={selectedLezione.data}
                onSaveSuccess={handleCloseModal} // Chiudi il modal dopo aver salvato
            />
        )}
    </Box>
</Modal>
  <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Vuoi gestire il registro presenze per il corso?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Questa azione permetterà la gestione del registro presenze per questo corso.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Annulla
                    </Button>
                    <Button onClick={handleUnlockPresenze} color="primary" autoFocus>
                        Conferma
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

const lezioneButtons = (lezioni, handleOpenModal) => {
    return lezioni.map((lezione, index) => (
        <Button
            key={lezione.id}
            variant="contained"
            style={{
                margin: '10px',
                backgroundColor: lezione.status == "1" ? '#4CAF50' : '#B0BEC5', // Verde o grigio
                color: '#fff',
                fontSize: '0.75rem', // Opzionale: riduci la dimensione del font
                width: '100px'
            }}
            onClick={() => handleOpenModal(lezione)}
        >
            Lezione {lezione.progr_lezione} {lezione.data!=null?dayjs(lezione.data).format('DD/MM/YYYY'):""}
        </Button>
    ));
};


export default CorsoLezioni;
