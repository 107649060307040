// src/components/CorsiCaricatiTable.js
import React, { useEffect, useState } from 'react';
import axios from './../axios';
import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/lib/styles.css'; // default theme
import 'react-tabulator/css/tabulator_simple.min.css'; // use Theme(s)


import CourseDetailsModal from './CourseDetailsModal';
import { Sparklines, SparklinesBars } from 'react-sparklines'; // Import Sparklines for the small chart
import ReactDOM from 'react-dom';

const CorsiCaricatiTable = ({ subtype }) => {
    const [data, setData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [graphDataCache, setGraphDataCache] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/anagrafiche/corsi-caricati?subtype=${subtype}`, {
                    withCredentials: true,
                });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [subtype]);

    const handleOpenModal = (userId) => {
        setSelectedUserId(userId);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedUserId(null);
    };


    const handleOpenURL = (pitoken) => {
        const url = `/mtauth?token=${pitoken}`;
        window.open(url, '_self'); // apre il link in una nuova scheda
    };

 

     // Funzione per formattare la colonna del grafico
     const graphFormatter = (cell, formatterParams, onRendered) => {
        const rowData = cell.getData();
        const userId = rowData.user_id;

        // Se i dati per l'utente sono già nella cache, mostra il grafico
        if (graphDataCache[userId]) {
            return renderGraph(graphDataCache[userId]);
        }

        // Altrimenti, recupera i dati dal server
        fetchGraphData(cell, userId);
        return '<div>Loading...</div>'; // Mostra un messaggio di caricamento temporaneo
    };

    // Funzione per recuperare i dati dell'attività di un utente
    const fetchGraphData = async (cell, userId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post('/datahandler/get-attivita', { token, userId });
            const activities = response.data.attivita.map(item => item.numero_attivita);

            // Aggiorna la cache
            setGraphDataCache(prevCache => ({
                ...prevCache,
                [userId]: activities,
            }));

            // Aggiorna il contenuto della cella con il grafico
            const graphHtml = renderGraph(activities);
            cell.getElement().innerHTML = graphHtml;
        } catch (error) {
            console.error('Errore nel recupero dei dati del grafico:', error);
            cell.getElement().innerHTML = '<div>Errore nel caricamento</div>';
        }
    };

    // Funzione per generare l'HTML del grafico con Sparklines
    const renderGraph = (activities) => {
        const container = document.createElement('div');

        const allZero = activities.every(val => val === 0);
        const nonZeroData = activities.filter(val => val > 0);
        const graph = (
            <>
                {allZero ? (
                    // Visualizza un messaggio o lascia la cella vuota se tutti i valori sono zero
                    <div style={{ textAlign: 'center', color: 'gray' }}></div>
                ) : (
                    <div style={{ margin: '0', padding: '0', width: '100%', height: '100%' }}>
                        <Sparklines data={activities} width={220} height={25}   
                         min={1}  
                        max={25} >
                            <SparklinesBars style={{ fill: "green", fillOpacity: ".8" }} />
                        </Sparklines>
                    </div>
                )}
            </>
        );
        ReactDOM.render(graph, container); // Usa ReactDOM per renderizzare React all'interno di un elemento HTML
        return container.innerHTML; // Restituisci l'HTML generato
    };
    


    const tableOptions = {
        height: "900px",
        selectable: true,
        virtualDom: true,
        virtualDomBuffer: 300,
        selectableRowsRangeMode: "click",
        rowFormatter: (row) => {
            const element = row.getElement();
            element.style.maxHeight = "36px";  // Imposta l'altezza massima desiderata
            element.style.overflow = "hidden"; // Nascondi il contenuto in eccesso
        },
    };


    const columns = [
        { title: 'Nome', field: 'NomeMT', headerFilter: 'input' },
        { title: 'Cognome', field: 'CognomeMT', headerFilter: 'input' },
        { title: 'Username', field: 'user_login', headerFilter: 'input' },
        { title: 'Email', field: 'user_email', headerFilter: 'input' },
        { title: 'Tipo', field: 'tipoteach', headerFilter: 'input' },
        {
            title: 'Numero Corsi', field: 'num_corsi', headerFilter: 'input', hozAlign: 'center' , formatter: (cell) => {
                const userId = cell.getRow().getData().user_id;
                const button = document.createElement('button');
                button.innerHTML = cell.getValue();
                button.className = 'course-button';
                button.onclick = () => handleOpenModal(userId);
                return button;
            }
        },
        { title: 'Numero DinoAlunni', field: 'numdinoalunni', headerFilter: 'input', formatter: 'number', hozAlign: 'right' },


        { 
            title: 'Visualizza', 
            field: 'pitoken', 
            headerFilter: 'input',  
            hozAlign: 'right',
            formatter: (cell) => {
                const pitoken = cell.getRow().getData().pitoken;
                const url = `/mtauth?token=${pitoken}`;
                
                // Crea l'elemento <a> con l'attributo href
                const link = document.createElement('a');
                link.href = url;
                link.innerHTML = "Visualizza";
                link.className = 'course-button'; // Aggiungi la classe per lo stile del pulsante
        
                // Imposta l'anchor per aprirlo nella stessa scheda
                link.target = "_self"; 
        
                // Restituisci l'elemento <a> come contenuto della cella
                return link;
            }
        
        },
        { title: 'Grafico Attività ultimo mese', field: 'numero_attivita', width:300, formatter: graphFormatter, hozAlign: 'center' },      


        
    ];

    return (
        
        <div className="tabulator-container">
             <div>
                <h3>Numero Magic Teachers: {data.length}</h3> {/* Aggiungi la didascalia qui */}
            </div>
            <ReactTabulator
                data={data}
                columns={columns}
                layout="fitData"
                options={tableOptions}
            />
            {selectedUserId && (
                <CourseDetailsModal
                    open={modalOpen}
                    handleClose={handleCloseModal}
                    userId={selectedUserId}
                    subtype={subtype}
                />
            )}
        </div>
    );
};

export default CorsiCaricatiTable;
